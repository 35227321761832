<!-- Header Structure -->
<template>
  <div id="footer" class="sticky-footer bg-primary py-4">
    <!-- Main -->
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <a href="https://worldfranchisecouncil.net">
            <img
              class="footer-logo mr-4"
              src="/static/images/Logo Fiaf.png"
              width="140"
              alt="image"
            />
          </a>          
          <p class="text-white">{{ $t('Components.FrontFooter.member') }}</p>
          <img class="footer-logo" src="/static/images/wfc.png" width="160" alt="image" />
          <br /><br />
          <p class="text-white">{{ $t('Components.FrontFooter.desc') }}</p>
        </div>

        <div class="col-md-4 col-sm-6">
          <ul class="footer-links w-100">
            <li>
              <router-link class="text-white" to="/">{{
                $t('Components.FrontFooter.link1')
              }}</router-link>
            </li>
            <li>
              <router-link class="text-white" to="/directory">{{
                $t('Components.FrontFooter.link2')
              }}</router-link>
            </li>
            <li>
              <router-link class="text-white" to="/news">{{
                $t('Components.FrontFooter.link3')
              }}</router-link>
            </li>
            <li>
              <router-link class="text-white" to="/news">{{
                $t('Components.FrontFooter.link4')
              }}</router-link>
            </li>
            <li>
              <a class="text-white" href="mailto:comunicaciones@portalfiaf.org?subject=free chocolate">{{
                $t('Components.FrontFooter.link5')
              }}</a>
            </li>
            <li>
              <router-link class="text-white" to="/">{{
                $t('Components.FrontFooter.link6')
              }}</router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-4 col-sm-12">
          <p class="text-white">
            {{ $t('Components.FrontFooter.address') }}: Av. das Nações Unidas, 10989 - 9 º
            e 11° andar, Conj. 92 e 112 Vl. Olímpia - São Paulo - SP, Brasil Cep:
            04578-000
          </p>
          <span class="text-white"
            ><v-icon color="#fff" class="mr-2">mdi-phone</v-icon>+55(11) 3020-8800 </span
          ><br />
          <span class="text-white"
            ><v-icon color="#fff" class="mr-2">mdi-email</v-icon>
            <a href="mailto:comunicaciones@portalfiaf.org" class="text-white"
              >comunicaciones@portalfiaf.org</a
            >
          </span>
          <v-row no-gutters class="mt-3">
            <a
              target="_blank"
              href="https://www.facebook.com/FIAFranquicias/?ref=pages_you_manage"
              class="btn btn-neutral btn-icon-only btn-round btn-lg mr-1 d-flex justify-content-center align-items-center"
              data-toggle="tooltip"
              data-original-title="Like us"
            >
              <i class="fa fa-2x fa-facebook-square"></i>
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/federaci%C3%B3n-iberoamericana-de-franquicias"
              class="btn btn-neutral btn-icon-only btn-lg btn-round mr-1 d-flex justify-content-center align-items-center"
              data-toggle="tooltip"
              data-original-title="Follow us"
            >
              <i class="fa fa-2x fa-linkedin"></i>
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCBot1AoFwAhGUcM-alcfNWQ"
              class="btn btn-neutral btn-icon-only btn-round btn-lg mr-1 d-flex justify-content-center align-items-center"
              data-toggle="tooltip"
              data-original-title="Follow us"
            >
              <i class="fa fa-2x fa-youtube"></i>
            </a>
          </v-row>
        </div>
      </div>
    </div>
    <!-- Copyright -->
    <div class="row">
      <div class="col-md-12">
        <div class="copyrights text-white py-2 mt-2">
          Copyright ©2022 Federación Iberoamericana de Franquicias FIAF. Todos los
          derechos reservados.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {}
</script>
<style lang="scss">
  $facebook: #3b5999 !default;
  $linkedin: #0a66c2;
  $youtube: #cd201f !default;
  .fa-facebook-square:hover {
    color: $facebook;
  }
  .fa-linkedin:hover {
    color: $linkedin;
  }
  .fa-youtube:hover {
    color: $youtube;
  }
</style>
